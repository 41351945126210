<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div
      class="modal fade"
      id="modal-form-reporte-general"
      style="background-color: #00000082"
      aria-hidden="true"
      data-backdrop="static"
      data-keyboard="false"
    >
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">Reporte General Operativo</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal1"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body pt-1 p-0">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
              <li class="nav-item">
                <a
                  class="nav-link active"
                  id="tab-Filtros"
                  data-toggle="tab"
                  href="#Filtros"
                  >Filtros
                </a>
              </li>
            </ul>
            <div class="tab-content" id="myTabContent">
              <div class="tab-pane fade active show" id="Filtros">
                <div class="card-body">
                  <div class="form-group">
                    <label for="">Fecha inicial</label>
                    <input
                      type="date"
                      class="form-control form-control-sm"
                      v-model="filtros.fecha_inicial"
                      :class="
                        $v.filtros.fecha_inicial.$invalid
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                      @input="validaFechas"
                    />
                  </div>
                  <div class="form-group">
                    <label for="">Fecha final</label>
                    <input
                      type="date"
                      class="form-control form-control-sm"
                      v-model="filtros.fecha_final"
                      :class="
                        $v.filtros.fecha_final.$invalid
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                      @input="validaFechas"
                    />
                  </div>
                  <div class="form-group row">
                    <label for="placa" class="col-md-5">Placa</label>
                    <input
                      type="text"
                      id="placa"
                      v-model="filtros.placa"
                      class="col-md-7"
                    />
                  </div>
                  <div class="form-group row">
                    <label for="remolque" class="col-md-5">Remolque</label>
                    <input
                      type="text"
                      id="remolque"
                      v-model="filtros.remolque"
                      class="col-md-7"
                    />
                  </div>
                  <div class="form-group row">
                    <label class="col-md-5">Cédula del conductor</label>
                    <input
                      type="number"
                      id="cedula_conductor"
                      v-model="filtros.cedula_conductor"
                      class="col-md-7"
                    />
                  </div>
                  <div class="form-group row">
                    <label class="col-md-5">Cédula del conductor 2</label>
                    <input
                      type="number"
                      id="cedula_conductor"
                      v-model="filtros.cedula_conductor_2"
                      class="col-md-7"
                    />
                  </div>
                  <div class="form-group row">
                    <label class="col-md-5">Tipo turno</label>
                    <select
                      class="form-control form-control-sm col-md-7"
                      v-model="filtros.tipo_turno"
                    >
                      <option value="">Seleccione...</option>
                      <option
                        v-for="tipo_turno in listasForms.tipoTurno"
                        :key="tipo_turno.id"
                        :value="tipo_turno.id"
                      >
                        {{ tipo_turno.nombre }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group row">
                    <label class="col-md-5">Tipo vehículo</label>
                    <select
                      class="form-control form-control-sm col-md-7"
                      v-model="filtros.tipo_vehiculo"
                    >
                      <option value="">Seleccione...</option>
                      <option
                        v-for="tipo_vehiculo in listasForms.tipoVehículo"
                        :key="tipo_vehiculo.id"
                        :value="tipo_vehiculo.id"
                      >
                        {{ tipo_vehiculo.nombre }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group row">
                    <label class="col-md-5">Bloque</label>
                    <select
                      class="form-control form-control-sm col-md-7"
                      v-model="filtros.bloque_id"
                    >
                      <option value="">Seleccione...</option>
                      <option
                        v-for="bloque_id in listasForms.tifBloque"
                        :key="bloque_id.id"
                        :value="bloque_id.id"
                      >
                        {{ bloque_id.nombre }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group row">
                    <label class="col-md-5">Operación</label>
                    <select
                      class="form-control form-control-sm col-md-7"
                      v-model="filtros.tipo_operacion"
                    >
                      <option value="">Seleccione...</option>
                      <option
                        v-for="tipo_operacion in listasForms.tifOperacion"
                        :key="tipo_operacion.numeracion"
                        :value="tipo_operacion.numeracion"
                      >
                        {{ tipo_operacion.descripcion }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group row">
                    <label class="col-md-5">Producto</label>
                    <select
                      class="form-control form-control-sm col-md-7"
                      v-model="filtros.tipo_producto"
                    >
                      <option value="">Seleccione...</option>
                      <option
                        v-for="tipo_producto in listasForms.tipoProducto"
                        :key="tipo_producto.id"
                        :value="tipo_producto.id"
                      >
                        {{ tipo_producto.nombre }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group row">
                    <label class="col-md-5">Ruta</label>
                    <select
                      class="form-control form-control-sm col-md-7"
                      v-model="filtros.ruta_id"
                    >
                      <option value="">Seleccione...</option>
                      <option
                        v-for="ruta_id in listasForms.tifRuta"
                        :key="ruta_id.id"
                        :value="ruta_id.id"
                      >
                        {{ ruta_id.nombre }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group row">
                    <label for="transportadora_id" class="col-md-5"
                      >Tipo tarifa</label
                    >
                    <select
                      class="form-control form-control-sm col-md-7"
                      v-model="filtros.tipo_tarifa"
                      :class="
                        $v.filtros.tipo_tarifa.$invalid
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                    >
                      <option value="">Seleccione...</option>
                      <option
                        v-for="tipo_tarifa in listasForms.tipoTarifa"
                        :key="tipo_tarifa.numeracion"
                        :value="tipo_tarifa.numeracion"
                      >
                        {{ tipo_tarifa.descripcion }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer justify-content-between">
            <div>
              <button
                type="button"
                class="btn btn-sm bg-navy"
                v-show="!$v.filtros.$invalid"
                @click="generarListadoExcel()"
                v-if="
                  $store.getters.can(
                    'tif.reportes.exportReporteGeneralOperativo'
                  )
                "
              >
                Generar Listado
                <i class="fas fa-file-download"> </i>
              </button>
            </div>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import axios from "axios";
import Loading from "../../../../components/Loading";
/* import vSelect from "vue-select"; */

export default {
  name: "TifReporteGeneralOperativoExport",
  components: {
    Loading,
    /*     vSelect, */
  },
  data() {
    return {
      cargando: false,
      filtros: {
        cedula_conductor: null,
        placa: null,
        remolque: null,
        fecha_inicial: "",
        hora_inicial: "00:00:00",
        fecha_final: "",
        hora_final: "23:59:00",
      },
      listasForms: {
        productos: [],
        empresas: [],
        tipoTurno: [],
        tipoTarifa: [],
        tipoVehículo: [],
        tifBloque: [],
        tifOperacion: [],
        tifRuta: [],
        tipoProducto: [],
      },
    };
  },
  validations: {
    filtros: {
      fecha_inicial: {
        required,
      },
      fecha_final: {
        required,
      },
      tipo_tarifa: {
        required,
      },
    },
  },
  methods: {
    validaFechas() {
      if (this.filtros.fecha_inicial) {
        const hoy = new Date();
        const fecha_inicial = new Date(this.filtros.fecha_inicial);
        fecha_inicial.setDate(fecha_inicial.getDate());

        if (fecha_inicial >= hoy) {
          this.filtros.fecha_inicial = null;
          this.$swal({
            icon: "error",
            title: `La fecha inicial debe ser menor o igual a la fecha actual...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }
      if (this.filtros.fecha_inicial && this.filtros.fecha_final) {
        var fecha_menor = new Date(this.filtros.fecha_inicial);
        var fecha_mayor = new Date(this.filtros.fecha_final);
        // Se valida que la fecha inicial sea menor que la fecha final
        if (fecha_menor > fecha_mayor) {
          this.filtros.fecha_final = null;
          this.$swal({
            icon: "error",
            title: `La fecha inicial no puede ser mayor a la fecha final...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }
      if (this.filtros.fecha_inicial && this.filtros.fecha_final) {
        fecha_menor = new Date(this.filtros.fecha_inicial).getTime();
        fecha_mayor = new Date(this.filtros.fecha_final).getTime();
        var diff = fecha_mayor - fecha_menor;
        var fecha_limite = diff / (1000 * 60 * 60 * 24);
        if (fecha_limite > 31) {
          this.filtros.fecha_final = null;
          this.$swal({
            icon: "error",
            title: `Las fechas no pueden ser mayor a 30 dias...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }
    },
    getTipoTarifa() {
      axios.get("/api/lista/96").then((response) => {
        this.listasForms.tipoTarifa = response.data;
      });
    },
    getTipoOperacion() {
      axios.get("/api/lista/120").then((response) => {
        this.listasForms.tifOperacion = response.data;
      });
    },
    getTipoTurno() {
      let me = this;
      var url = "api/admin/turnos/lista";
      axios
        .get(url, {
          params: {
            linea_negocio_id: 3,
          },
        })
        .then(function(response) {
          let respuesta = response.data;
          me.listasForms.tipoTurno = respuesta;
        })
        .catch(function(error) {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },
    getTipoVehículo() {
      let me = this;
      var url = "api/admin/tiposVehiculos/lista";
      axios
        .get(url, {
          params: {
            linea_negocio_id: 3,
          },
        })
        .then(function(response) {
          let respuesta = response.data;
          me.listasForms.tipoVehículo = respuesta;
        })
        .catch(function(error) {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },
    getBloque() {
      let me = this;
      var url = "api/admin/bloques/lista";
      axios
        .get(url, {
          params: {
            linea_negocio_id: 3,
          },
        })
        .then(function(response) {
          let respuesta = response.data;
          me.listasForms.tifBloque = respuesta;
        })
        .catch(function(error) {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },
    getTipoProducto() {
      let me = this;
      var url = "api/admin/productos/lista";
      axios
        .get(url, {
          params: {
            linea_negocio_id: 3,
          },
        })
        .then(function(response) {
          let respuesta = response.data;
          me.listasForms.tipoProducto = respuesta;
        })
        .catch(function(error) {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },
    getTipoRuta() {
      let me = this;
      var url = "/api/tif/rutas/lista";
      axios
        .get(url)
        .then(function(response) {
          let respuesta = response.data;
          me.listasForms.tifRuta = respuesta;
        })
        .catch(function(error) {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },
    generarListadoExcel() {
      this.cargando = true;
      axios({
        method: "POST",
        url: "/api/tif/turnos/tifReporteGeneralOperativo",
        data: this.filtros,
      })
        .then((response) => {
          let data = response.data;
          this.cargando = false;
          location.href = data.url;
          this.$swal({
            icon: "success",
            title: data.message,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.cargando = false;
        });
    },

    limpiarModal() {
      this.filtros = {
        cedula_conductor: null,
        placa: null,
        remolque: null,
        fecha_inicial: "",
        hora_inicial: "00:00:00",
        fecha_final: "",
        hora_final: "23:59:00",
      };
    },
  },

  mounted() {
    this.getTipoTarifa();
    this.getTipoTurno();
    this.getTipoVehículo();
    this.getBloque();
    this.getTipoRuta();
    this.getTipoProducto();
    this.getTipoOperacion();
    this.cargando = true;
    this.cargando = false;
  },
};
</script>
